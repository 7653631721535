<template>
  <div>
    <section class="login-content">
      <div class="row m-0 align-items-center bg-white vh-100">
        <div class="col-md-6">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="card card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                <div class="card-body">
                  <router-link :to="{name: 'default.dashboard'}" class="navbar-brand d-flex align-items-center mb-3">
                    <svg width="30" class="text-primary" viewBox="0 0 30 30" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2"
                            transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                      <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)"
                            fill="currentColor" />
                      <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)"
                            fill="currentColor" />
                      <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2"
                            transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                    </svg>
                    <h4 class="logo-title ms-3">Delivery Bot</h4>
                  </router-link>
                  <h1 class="mb-2 text-center">Авторизация</h1>
                  <Form @submit="login" v-slot="{ meta }">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="email" class="form-label">Эл.почта</label>
                          <Field v-model="email" type="email" class="form-control" id="email" name="email"
                                 label="Эл.почта"
                                 placeholder=" " rules="email|required" />
                          <ErrorMessage class="text-danger" name="email"></ErrorMessage>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="password" class="form-label">Пароль</label>
                          <Field v-model="password" type="password" class="form-control" id="password"
                                 aria-describedby="password"
                                 placeholder=" " rules="required" name="password" />
                          <ErrorMessage class="text-danger" name="password"></ErrorMessage>
                        </div>
                      </div>
                      <!-- <div class="col-lg-12 d-flex justify-content-end">
                        <router-link :to="{ name: 'auth.recoverPassword' }">Забыли пароль?</router-link>
                      </div> -->
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                      <button
                        :disabled="!(meta.valid && meta.dirty) || isLoading"
                        type="submit"
                        class="btn btn-primary px-5"
                      >
                        Войти
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div class="sign-bg">
            <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.05">
                <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857"
                      transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857"
                      transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857"
                      transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857"
                      transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
              </g>
            </svg>
          </div>
        </div>
        <div class="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
          <img src="@/assets/images/auth/01.png" class="img-fluid gradient-main animated-scaleX" alt="images">
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useAuthStore from '@/store/auth'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { ApiServices } from '@/services'
import { useToast } from 'vue-toastification'

export default {
  name: 'SignIn',
  components: {
    Form, Field, ErrorMessage
  },
  setup () {
    const auth = useAuthStore()
    const router = useRouter()
    const toast = useToast()

    const email = ref('')
    const password = ref('')
    const isLoading = ref(false)

    const login = async () => {
      isLoading.value = true

      try {
        const { data: loginResponse } = await ApiServices.auth.signIn({
          email: email.value,
          password: password.value
        })

        isLoading.value = false
        await auth.login(loginResponse.data)

        await router.push({ name: 'default.dashboard' })
      } catch (e) {
        isLoading.value = false
        Object.entries(e.response.data.error).forEach(([fieldName, error]) => {
          const [errorText] = error
          toast.error(errorText)
        })
      }
    }

    return {
      login,
      email,
      password,
      isLoading
    }
  }
}
</script>
